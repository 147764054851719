
import { Vue } from "vue-class-component";
import axios from '@/plugins/axios';
import { ElMessageBox } from 'element-plus';

interface Result {
    data: any,
    message: string,
    name: string,
    type: string,
    listAttribute: any[]
}
interface Attr {
    name: string
}

export default class UserPage extends Vue {
    name = 'Application Keys';
    headers = {
        'token-user': localStorage.getItem('tokenUser'),
        'account': localStorage.getItem('account'),
    };
    listData: any = []; 
    formData: any = {
        id: '',
        name: '',
        type: '',
        listAttribute: [],
    };

    formAttribute: any = {
        name: '',
        value: ''
    }
    titleModal = ''
    dialogApplication = false;

    async created() {
        this.getList()
    }

    async getList() {
        let res: Result
        await this.$store.dispatch("setLoading", true, { root: true });
        res = await axios.get('admin/application-key/list', { 
            headers : this.headers,
        })
        if (res) {
            this.listData = res.data
        }
        await this.$store.dispatch("setLoading", false, { root: true });
    }

    async handleDelete(id: string, index: number) {
        let res: Result
        ElMessageBox.confirm('Do you want to delete Application Key?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(async ()=> {
                res = await axios.delete('admin/application-key',{  
                    params: { id: id }, 
                    headers : this.headers
                })
                if(res) {
                    this.$message.success(res.message)
                    this.listData.splice(index, 1)
                }
            }
        )
    }
    handleAddAttribute() {
        if (this.formAttribute.name === '') {
            return this.$message.warning("Please input the name attribute!")
        }
        if (this.formAttribute.value === '') {
            return this.$message.warning("Please input the value attribute!")
        }
        let attr: any
        for (attr of this.formData.listAttribute) {
            if (attr.name == this.formAttribute.name) {
                return this.$message.warning('Attribute Name ' + this.formAttribute.name + ' exist', 'Error message!!!')
            }
        }
        this.formData.listAttribute.push({
            'name': this.formAttribute.name,
            'value': this.formAttribute.value
        })
        this.formAttribute.name = ''
        this.formAttribute.value = ''
    }
    handleRemoveAttribute(index: any) {
        this.formData.listAttribute.splice(index, 1)
    }
    async dialogOpenModal(row : any) {
        if (row) {
            this.titleModal = 'Update Application Key'
            this.formData.id = row._id
            let res: Result
            res = await axios.get('admin/application-key/detail', {
                params: { id: row._id }, 
                headers : this.headers,
            })
            if (res) {
                this.formData.name = res.name
                this.formData.type = res.type
                this.formData.listAttribute = res.listAttribute
            }
            this.dialogApplication = true
        } else {
            this.formData.name = ''
            this.formData.type = ''
            this.formData.listAttribute = []
            this.titleModal = 'Add Application Key'
            this.dialogApplication = true
        }
    }

    async handleSave () {
        if(this.formData.name == '') {
            this.$message.warning("Please input the name !")
            return
        }
        if(this.formData.type == '') {
            this.$message.warning("Please input the type !")
            return
        }
        console.log(this.formData);
        

        let res: Result
        if (this.formData.id) {
            res = await axios.put('admin/application-key', this.formData, { 
                headers : this.headers,
            })
        } else {
            res = await axios.post('admin/application-key', this.formData, { 
                headers : this.headers,
            })
        }

        if (res) {
            this.$message.success(res.message)
            this.getList()
            this.dialogApplication = false
            this.formData = {
                name: '',
                type: ''
            }
            this.formAttribute = {
                name: '',
                value: ''
            }
        }
    }
}
